import $ from 'jquery';
import 'jquery-ui/ui/widgets/tabs';
import 'slick-carousel';
import objectFitImages from 'object-fit-images';
import 'imagesloaded';
import Rellax from 'rellax';
import Masonry from 'masonry-layout';

$(document).on('click', 'a[href^="#"]', function (event) {
  event.preventDefault();
  $('html, body').animate(
    {
      scrollTop: $($.attr(this, 'href')).offset().top,
    },
    500
  );
});

$(document).ready(function () {
  $(function () {
    objectFitImages();
  });

  $('#search-icon-mobile').click(function () {
    $('#searchbar-mobile').toggleClass('open');
  });

  $('.vacature-slider').slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $('.klant-slider').slick({
    // autoplay: true,
    // autoplaySpeed: 2000,
    dots: true,
    arrows: false,
  });
  $('.men-slider').slick({
    // autoplay: true,
    // autoplaySpeed: 2000,
    dots: true,
    arrows: false,
  });

  // parallax effect
  let rellax = new Rellax('.rellax', {
    // wrapper: '.custom-element'
  });
});


// =================================================
// masonry effect on portfolio
// =================================================
$(function () {
  // Thanks to Mark Avery
  // http://stackoverflow.com/questions/8191015/how-do-i-lay-out-my-content-divs-in-a-similar-manner-to-facebook-timeline
  var adjustArticleHeights = (function () {

    var leftColumnHeight = 0,
      rightColumnHeight = 0,
      $portfolios = $('portfolio-card');

    for (var i = 0; i < $portfolios.length; i++) {
      // This just adds random heights to portfolios
      $portfolios.eq(i).height(Math.floor(Math.random() * 300) + 10);

      if (leftColumnHeight > rightColumnHeight) {
        rightColumnHeight += $portfolios.eq(i).addClass('right').outerHeight(true);
      } else {
        leftColumnHeight += $portfolios.eq(i).outerHeight(true);
      }
    }

    return $portfolios;
  })();
});

//=================================================
//if overlap-top given to a section
//=================================================
$(document).ready(function () {
  // const overlapTopItem = document.querySelector('.overlap-top');
  // const prevOverlapTopItem = overlapTopItem.parentNode.previousElementSibling;

  // const overlapBottomItem = document.querySelector('.overlap-bottom');
  // const nextOverlapBottomItem = overlapBottomItem.parentNode.nextElementSibling;

  // function Overlap() {
  //   if (overlapTopItem) {
  //     prevOverlapTopItem.classList.add('space-bottom');
  //   } else if (overlapBottomItem) {
  //     nextOverlapBottomItem.classList.add('space-top');
  //   } else if (overlapTopItem && overlapBottomItem) {
  //     prevOverlapTopItem.classList.add('space-bottom');
  //     nextOverlapBottomItem.classList.add('space-top');
  //   } else console.log('there is no item that must be overlapping');
  // }

  // Overlap();
  // console.log('there is no item that must be overlapping');


  $('section').each(function () {
    if ($(this).find('.overlap-top').length > 0) {
      $('.overlap-top', this).parent().prev().addClass('space-bottom')
    }
  });
  $('section').each(function () {
    if ($(this).find('.overlap-bottom').length > 0) {
      $('.overlap-bottom', this).parent().next().addClass('space-top')
    }
  })
});



//=================================================
// -----------------  Load More  ------------------
//=================================================

$('.btn-cont .load-more').click(function () {
  let current_page = document.querySelector('.portfolio').dataset.page;
  const max_pages = document.querySelector('.portfolio').dataset.max;

  const btn = $(this),
    data = {
      action: 'load_more_projects',
      max: max_pages,
      page: current_page,
    };
  $.ajax({
    url: ajax_object.ajax_url,
    data,
    type: 'POST',
    beforeSend(xhr) {
      btn.text('Laden...');
    },
    success(data) {
      if (data) {
        btn.text('Laad meer');
        const list = document.querySelector('.portfolio');
        list.innerHTML += data;
        list.dataset.page++;
        current_page = document.querySelector('.portfolio').dataset.page;
        if (current_page == max_pages) {
          btn.remove();
        }
      } else {
        btn.remove();
      }
    },
  });
});

// -------------------    menu scroll ---------------------

const body = document.body;
let lastScroll = 0;

window.addEventListener("scroll", () => {
  const currentScroll = window.pageYOffset;
  if (currentScroll <= 0) {
    body.classList.remove("scroll-up");
    return;
  }

  if (currentScroll > lastScroll && !body.classList.contains("scroll-down")) {
    body.classList.remove("scroll-up");
    body.classList.add("scroll-down");
  } else if (
    currentScroll < lastScroll &&
    body.classList.contains("scroll-down")
  ) {
    body.classList.remove("scroll-down");
    body.classList.add("scroll-up");
  }
  lastScroll = currentScroll;
});

$(document).ready(function () {
  $('.burger').click(function () {
    $(this).toggleClass('open');
    $('#mobile-menu').toggleClass('open');
    // body.classList.add("scroll-up");
    $('header').toggleClass('color-style');
  });
});